export const defaultLang = 'ko';
export const supportedLangs = {
  ko: '한국어',
  en: 'English',
};
export const resources = {
  ko: {
    //nav_menu
    nav_menu_1: '회사소개',
    nav_menu_2: '솔루션',
    nav_menu_3: '활용사례',
    nav_menu_4: '문의하기',

    // footer
    footer_partnership_1: '제휴문의',
    footer_partnership_2: '고객의 디지털 업무 혁신 고민을 신속하게 해결합니다.',
    footer_partnership_3: '문의하기',
    footer_address_1:
      '(04781) 서울 성동구 성수일로4길 25 서울숲코오롱디지털타워 1차 19층 1902호 02-465-3737',
    footer_singapore_address: '41 Middle Road #04-00 Boon Sing Building, Singapore 188950',
    footer_privacy: '개인정보취급방침',

    //MainPage
    //----------MainName
    mainpage_mainName_1: '메타버스 기반 새로운 디지털 혁신을 제공합니다.',
    mainpage_mainName_2: 'XR기반 메타버스 플랫폼으로 새로운 경험을 선사합니다.',
    mainpage_mainName_3: '최고 전문가들이 모여 메타버스 산업을 선도합니다.',

    //NewMainPage
    //----------MainName
    newMainPage_pc_headTitle_1: 'XR 기반 산업용 협업 플랫폼 전문 기업',
    newMainPage_mb_headTitle_1: 'XR 기반<br />산업용 협업 플랫폼 전문 기업',
    newMainPage_pc_bodyText:
      '제조, EPC, 교육, 헬스케어 등 산업 전반의 디지털 혁신을 위한 기업형 리모트 원격 협업 플랫폼 제공<br />협업 공간부터 디지털 트윈까지 산업별 맞춤형 서비스 구축 지원',
    newMainPage_mb_bodyText:
      '제조, EPC, 교육, 헬스케어 등 산업 전반의<br />디지털 혁신을 위한 기업형 리모트 원격 협업 플랫폼 제공<br />협업 공간부터 디지털 트윈까지 산업별 맞춤형 서비스 구축 지원',

    //----------MainTechnology
    newMainPage_technology1_Title: '산업용 메타버스 전문기업',
    newMainPage_pc_technology1_Text:
      '산업과 메타버스 기술을 융합하여 산업 현장의 문제를 해결합니다.',
    newMainPage_mb_technology1_Text:
      '산업과 메타버스 기술을 융합하여 산업 현장의<br />문제를 해결합니다.',
    newMainPage_technology2_Title: '고객 가치 실현',
    newMainPage_pc_technology2_Text:
      '각 분야의 전문 지식을 바탕으로 고객 맞춤형 솔루션을 제공합니다.',
    newMainPage_mb_technology2_Text:
      '각 분야의 전문 지식을 바탕으로<br />고객 맞춤형 솔루션을 제공합니다.',

    //----------MainSolution
    newMainPage_solution_MXspace_txt: '기업형 리모트 협업 XR 기반<br />워크스페이스 플랫폼',
    newMainPage_solution_pc_MXview_txt:
      '대규모 플랜트 시공을 위한<br />선도적 디지털<br />건설 관리 플랫폼',
    newMainPage_solution_mb_MXview_txt:
      '대규모 플랜트 시공을 위한<br />선도적 디지털 건설 관리 플랫폼',
    newMainPage_solution_pc_MX3d_optimizer_txt: '캐드 데이터 컨버전<br />자동 분할 경량화 솔루션',
    newMainPage_solution_mb_MX3d_optimizer_txt: '캐드 데이터 컨버전 자동 분할<br />경량화 솔루션',
    newMainPage_solution_MXdts_txt: '설비 운영을 위한 디지털트윈<br />트레이닝 시뮬레이터 서비스',
    newMainPage_solution_pc_MXatg_txt:
      '산업 현장 특수 정밀 기기<br />관리를 위한 선도적<br />디지털 건설 관리 솔루션',
    newMainPage_solution_mb_MXatg_txt:
      '산업 현장 특수 정밀 기기 관리를<br />위한 선도적 디지털<br />건설 관리 솔루션',

    //----------MainNews
    mainNews_txt_1: '[人사이트]박태욱 옐로테일 대표 "산업 특화 메타버스로 원격 업무 지원"',
    mainNews_txt_2: '박태욱 와이엠엑스 대표, &lsquo;대한민국 IT서비스 혁신대상&rsquo; 장관상 수상',
    mainNews_txt_3: '와이엠엑스, 국내 최대 SW 대전 참가…산업용 메타버스 플랫폼 선봬',
    mainNews_txt_4:
      '와이엠엑스-스마트인재개발원, &lsquo;메타버스 기반 디지털 교육 플랫폼&rsquo; 사업 협력',
    mainNews_txt_5: '와이엠엑스, 메타버스 기반 &lsquo;중대재해 예방 서비스&rsquo; 선보여',
    mainNews_txt_6: '와이엠엑스, KISTI &lsquo;개방형 XR 플랫폼&rsquo; 사업 수주 "최초 표준화"',
    mainNews_txt_7:
      '와이엠엑스, &lsquo;디지털플랫폼정부&rsquo; 국제학술대회 참가…해외진출 &lsquo;시동&rsquo;',
    mainNews_txt_8: '와이엠엑스, 한국기술교육대 &lsquo;메타버스&rsquo; 용역 수주',
    mainNews_txt_9: '와이엠엑스, 건설기계장비 &lsquo;장애 진단·해결&rsquo; AR 솔루션 출시',
    mainNews_txt_10: '와이엠엑스, 53억 규모 시리즈A 투자 유치',
    mainNews_txt_11: 'CES 첫 참가 와이엠엑스 박태욱 대표 "국내외 바이어 큰 관심 기대" [CES 2024]',

    mainNews_channel_et_news: '전자신문',
    mainNews_channel_wowtv: '한국경제TV',
    mainNews_channel_d_daily: '디지털데일리',
    mainNews_channel_fn_nfews: '파이낸셜뉴스',
    mainNews_channel_newsis: '뉴시스',
    mainNews_channel_chosun_biz: '조선비즈',
    mainNews_channel_mk: '매일경제',
    mainNews_channel_e_today: '이투데이',

    //about
    about_headTitle: 'XR 기반 Metaverse 플랫폼 전문기업 YMX',
    about_headTitle_m: 'XR 기반 Metaverse<br/>플랫폼 전문기업 YMX',

    about_bodyText:
      '제조, EPC, 교육, 헬스케어 등 산업 전반의 디지털 혁신을 위한 메타버스 플랫폼 제공<br/>협업 공간부터 디지털 트윈까지 산업별 맞춤형 서비스 구축 지원',
    about_bodyText_m:
      '제조, EPC, 교육, 헬스케어 등 산업 전반의<br/>디지털 혁신을 위한 메타버스 플랫폼 제공<br/>협업 공간부터 디지털 트윈까지 산업별 맞춤형 서비스 구축 지원',

    //----------aboutHistory
    month: ['', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],

    about_hisoty_2024_03_1: '싱가포르 Geo Connect Asia 2024 컨퍼런스 참가',
    about_hisoty_2024_02_1: '싱가포르 현지 법인 YMX Singapore PTE LTD 설립',
    about_hisoty_2024_01_1: '미국 CES 2024 컨퍼런스 참가',
    about_hisoty_2024_01_2: '싱가포르 YJP SURVEYORS PTE LTD 와 Joint Venture(합작투자) 계약 체결',

    about_hisoty_2023_12_1: '과학기술정보통신부 메타버스이노베이션어워드  우수상 수상',
    about_hisoty_2023_12_2: '유한대학교 산학협력 MOU 체결',
    about_hisoty_2023_11_1: '삼성 SDS XR Digital Twin 파트너쉽 체결',
    about_hisoty_2023_11_2: '한국폴리텍V대학 디지털 혁신 기술 분야 미래 인재 양성 MOU 체결',
    about_hisoty_2023_11_3: '박태욱 대표 한국국방경영분석학회 이사 위촉',
    about_hisoty_2023_11_4: 'NHN에듀 XR기술 및 메탑버스 플랫폼사업 MOU 체결',
    about_hisoty_2023_10_1: '서울시 서울형 강소기업 선정',
    about_hisoty_2023_08_1: '총 53억원 규모 ‘시리즈 A’ 투자 유치',
    about_hisoty_2023_05_1: '한국IT서비스학회  ‘2023 춘계학술대회’ 컨퍼런스 참가',
    about_hisoty_2023_05_2: 'KISTI ‘개방형 AR플랫폼’사업 수주 및 업계 최초 ‘표준화’',
    about_hisoty_2023_05_3:
      '‘원스톱’ XR건설 시공 검수 솔루션과 메타버스 플랫폼 (Mxspcae)연계 서비스 출시',
    about_hisoty_2023_02_1: '성균관대학교 산학협력단 산학협력 MOU 체결',

    about_hisoty_2022_11_1: '‘대한민국 IT서비스 혁신 대상’ 행정 안전부 장관상 수상',
    about_hisoty_2022_11_2:
      '스마트 인재개발원 ‘메타버스 기반 디지털 교육 플랫폼 사업 협력’ MOU 체결',
    about_hisoty_2022_10_1: '㈜옐로테일에서 ㈜와이엠엑스로 사명 변경',
    about_hisoty_2022_9_1: '일본 AOS Data 컨퍼런스 참가',
    about_hisoty_2022_7_1: '산업용 메타버스 플랫폼 ‘MXspace’ 출시',
    about_hisoty_2022_7_2: '화상, 음성채팅 솔루션 ‘MXrtc’ 출시',

    about_hisoty_2021_11_1:
      '‘MXview’ 품질검수 솔루션 기반으로 삼성 그룹 내 ‘21년 올해의 기술상’ 수상',
    about_hisoty_2021_9_1: 'XR 기반 설비·점검 지원 솔루션 ‘MXatg’ 출시',
    about_hisoty_2021_6_1: 'BIM 기반 AR 품질검수 솔루션 ‘MXview’ 출시',
    about_hisoty_2021_1_1: 'LGCNS XR Digital Twin 파트너쉽 체결',

    about_hisoty_2020_12_1: '로크웰 오토메이션 코리아 파트너십 체결',

    about_hisoty_2019_10_1: 'Unity코리아 파트너십 체결',
    about_hisoty_2019_8_1: '가상 설비 교육 훈련 시뮬레이터 솔루션 ‘MXdts’ 출시',

    about_hisoty_2018_8_1: 'AI기반 IoT 솔루션 ‘MXiot’ 출시',
    about_hisoty_2018_5_1: 'PTC코리아 파트너십 체결',
    about_hisoty_2018_5_2: 'Unity코리아 Unite 컨퍼런스 참가',
    about_hisoty_2018_2_1: '서울산업진흥원 하이 서울 브랜드 혁신기업 선정',
    about_hisoty_2018_1_1: '㈜옐로테일 설립',

    //business
    business_datail: '자세히 보기',

    business_MXspace_title: 'XR 기반 원격 협업 플랫폼<br />기업형 메타버스',
    business_MXspace_body:
      '자체 개발한 메타버스 플랫폼 기반으로<br />다양한 솔루션, 콘텐츠 등을 연동해 고객이 원하는 모든 메타버스 서비스를 제공합니다.',

    business_MXview_title: '산업 건축 시공을 위한 <br />디지털 품질 관리 솔루션',
    business_MXview_body:
      '건설, 선박, 플랜트 등 EPC 분야 대용량 BIM 3D 데이터 최적화를 통해 AR 서비스로 제공합니다.<br />현장과 업무시스템 실시간 원격 연동을 통해 품질·검수 관리·자동화·협업 등의 효율성을 극대화합니다.',

    business_MX3d_optimizer_title: '캐드 포맷 변환, 분할 및 경량화 솔루션',
    business_MX3d_optimizer_body:
      '디지털트윈 개발 엔진인 유니티3D, 언리얼, 엔비디아 옴니버스에 적용 가능한 포맷으로<br />컨버전 및 경량화, 분할 기능 지원합니다.',

    business_MXdts_title: '설비 운영을 위한 디지털트윈<br />가상 시뮬레이터',
    business_MXdts_body:
      '메타버스 플랫폼 MXspace 기반으로 스마트팩토리 공장을 3D 디지털 가상 공장으로<br />디지털트윈화 할 수 있도록 지원합니다.',

    business_Mxtsg_title: '산업 현장 특수 정밀 기기 관리를 위한<br />XR 디지털 관리 솔루션',
    business_Mxtsg_body:
      'XR 기술 기반으로 건설, 항공, 국방, 의료 등 다양한 산업 현장에서 특수·정밀 기기에 대한 교육, 성능 테스트,<br />상태 점검, 정비 보조 등의 활용을 통해 신속하게 기술을 확보 및 활용하도록 지원합니다.',

    //project
    project_maintitle:
      '다양한 산업군에서의 디지털 혁신 경험을 바탕으로<br />고객과 함께 비즈니스 성공 사례를 만들어 가겠습니다.',
    project_maintitle_m:
      '다양한 산업군에서의<br />디지털 혁신 경험을 바탕으로<br />고객과 함께 비즈니스 성공 사례를 만들어 가겠습니다.',
    project_videoTitle_1: '시설물 점검 공간인식 AR 서비스',
    project_videoTitle_2: '문구 산업 현장 모니터링 AR서비스',
    project_videoTitle_3: '마케팅앱 AR 서비스',
    project_videoTitle_4: '건설 현장 검측 교육용 VR 서비스',
    project_videoTitle_5: '중장비 성능 시험 AR 서비스',

    //contact
    contact_mainTitle_1: '문의 하기',
    contact_required_1: '항목은 필수 입력사항',
    contact_bodyTitle_1: '문의 유형',
    contact_bodyTitle_2: '고객 정보',
    contact_bodyTitle_3: '산업군별',
    contact_bodyTitle_4: '문의 내용',
    contact_bodyTitle_4_1: '상세내용을 입력해주세요',

    contact_bodyText_1_1: '제품 문의',
    contact_bodyText_1_2: '기타',

    contact_bodyText_2_1: '고객명',
    contact_bodyText_2_1_2: '고객명',
    contact_bodyText_2_2: '이메일',
    contact_bodyText_2_2_2: '이메일',
    contact_bodyText_2_3: '연락처',
    contact_bodyText_2_4: '기업/기관',
    contact_bodyText_2_4_2: '기업/기관',
    contact_bodyText_2_5: '부서 ',
    contact_bodyText_2_6: '직급 ',

    contact_bodyText_3_1: 'IT개발',
    contact_bodyText_3_2: '서비스',
    contact_bodyText_3_3: '교육',
    contact_bodyText_3_4: '의료',
    contact_bodyText_3_5: '공공기관',
    contact_bodyText_3_6: '도소매',
    contact_bodyText_3_7: '제조업',
    contact_bodyText_3_8: '기타',

    contact_privacy_1: '개인정보보호정책',
    contact_privacy_2: '에 동의합니다.',

    contact_complete_1: '문의가 완료되었습니다.',
    contact_complete_2: '완료',

    contact_address_1: '성동구 성수일로4길 25',
    contact_address_2: '서울숲코오롱디지털타워 1차 19층 1902호',
    contact_singapore_address: '41 Middle Road #04-00 Boon Sing Building, Singapore 188950',
    contact_menu_1: '구매 및 제휴 문의',
    contact_menu_2: '제품사용 및 기술 자문',
  },
  en: {
    //nav_menu
    nav_menu_1: 'About Us',
    nav_menu_2: 'Solution',
    nav_menu_3: 'Projects',
    nav_menu_4: 'Contact Us',

    // footer
    footer_partnership_1: 'Partnership',
    footer_partnership_2: "We quickly solve customers' digital business innovation concerns.",
    footer_partnership_3: 'Contact Us',
    footer_address_1:
      '1902, 19F, Seoul forest kolon digital tower, 25, Seongsuil-ro 4-gil, Seongdong-gu, Seoul, Korea 04781, 02-465-3737',
    footer_singapore_address: '41 Middle Road #04-00 Boon Sing Building, Singapore 188950',
    footer_privacy: 'Privacy Policy',

    //MainPage
    //----------MainName
    mainpage_mainName_1: 'We provide new digital innovation based on the metaverse.',
    mainpage_mainName_2: 'We provide new experiences with the XR-based metaverse platform.',
    mainpage_mainName_3: 'The best experts gather to lead the metaverse industry.',

    //NewMainPage
    //----------MainName
    newMainPage_headTitle_1:
      'YMX specializes in XR-based with<br/>remote collaboration platforms for enterprise',
    newMainPage_pc_bodyText:
      "We provide the most comprehensive enterprise remote collaboration platform for digital innovation<br/>across industries, including manufacturing, EPC, education, healthcare, and more.​<br/><br/>YMX's customized solutions are industry-specific and support build services from<br/>collaboration spaces to digital twins.",
    newMainPage_mb_bodyText:
      "We provide the most comprehensive enterprise remote collaboration platform for digital innovation<br/>across industries, including manufacturing, EPC, education, healthcare, and more.​<br/><br/>YMX's customized solutions are industry-specific and support build services from<br/>collaboration spaces to digital twins.",

    //----------MainTechnology
    newMainPage_technology1_Title: 'The specialized Industrial metaverse company​',
    newMainPage_pc_technology1_Text:
      'Combines industry and metaverse technologies<br/>to address pain points on-site. ',
    newMainPage_mb_technology1_Text:
      'Combines industry and metaverse technologies<br/>to address pain points on-site. ',
    newMainPage_technology2_Title: "Achieving Customer's perceived value​",
    newMainPage_pc_technology2_Text:
      'Based on specialized knowledge in each sector,<br/>we customize solutions for you. ​',
    newMainPage_mb_technology2_Text:
      'Based on specialized knowledge in each sector,<br/>we customize solutions for you. ​',

    //----------MainSolution
    newMainPage_solution_MXspace_txt:
      'The enterprise type of ​workspace platform for ​XR-based remote collaboration. ​',
    newMainPage_solution_mb_MXview_txt:
      'Leading the Digital Construction Management Platform for Large Scale Plants working on-site.',
    newMainPage_solution_pc_MXview_txt:
      'Leading the Digital Construction Management Platform for Large Scale Plants working on-site.',
    newMainPage_solution_mb_MX3d_optimizer_txt:
      'Cad Data Conversion Automated Partitioning Lightweight Solution',
    newMainPage_solution_pc_MX3d_optimizer_txt:
      'Cad Data Conversion Automated Partitioning Lightweight Solution',
    newMainPage_solution_MXdts_txt:
      'Digital Twin Training Simulator Services for Facility Operations',
    newMainPage_solution_mb_MXatg_txt:
      'Leads the Digital Construction management solution for managing specialized precision instruments on industrial sites.',
    newMainPage_solution_pc_MXatg_txt:
      'Leads the Digital Construction management solution for managing specialized precision instruments on industrial sites.',

    //----------MainNews
    mainNews_txt_1:
      'Tae-Wook Park, CEO of Yellowtail, "Industry-specific metaverse for remote work"',
    mainNews_txt_2:
      'Awarded the Minister of Public Administration and Security Award at ‘The Korea IT Service Innovation Award’',
    mainNews_txt_3:
      'YMX, Industrial metaverse platform presented to the public at ‘the 2022 Korea Software Competition’',
    mainNews_txt_4:
      'YMX-Signed MOU with Smart Human Resources Development Center for ‘Business Cooperation on Digital Education Platform Based on Metaverse’',
    mainNews_txt_5:
      'YMX, Metaverse-based ‘Critical Disaster ​Prevention Service’ presented to the public',
    mainNews_txt_6: 'YMX, KISTI ‘Open XR Platform’ Project Order "First Standardized＂',
    mainNews_txt_7:
      'YMX, Participated in the ‘Digital Platform Government’ International Conference​',
    mainNews_txt_8: 'YMX, Ordered ‘Metaverse’ service from KOREATECH University​',
    mainNews_txt_9:
      'YMX launched AR solution to ‘troubleshoot, detection and resolve’ construction machinery equipment​',
    mainNews_txt_10: 'YMX Attracted a total of $4 million in ‘Series A’ investment​',
    mainNews_txt_11:
      '[CES 2024] CEO of YMX Tae-Wook Park "Expecting great interest from foreign and domestic buyers"​',

    mainNews_channel_et_news: '전자신문',
    mainNews_channel_wowtv: '한국경제TV',
    mainNews_channel_d_daily: '디지털데일리',
    mainNews_channel_fn_nfews: '파이낸셜뉴스',
    mainNews_channel_newsis: '뉴시스',
    mainNews_channel_chosun_biz: '조선비즈',
    mainNews_channel_mk: '매일경제',
    mainNews_channel_e_today: '이투데이',

    //about
    about_headTitle: 'YMX is XR Industrial Metaverse Platform',
    about_headTitle_m: 'YMX is XR Industrial Metaverse Platform',

    about_bodyText:
      "We provide the most comprehensive enterprise<br />remote collaboration platform for digital innovation<br />across industries, including manufacturing, EPC, education,<br />healthcare, and more.<br /><br />YMX's customized solutions are industry-specific and support build services from<br />collaboration spaces to digital twins.",
    about_bodyText_m:
      "We provide the most comprehensive enterprise<br />remote collaboration platform for digital innovation<br />across industries, including manufacturing, EPC, education,<br />healthcare, and more.<br /><br />YMX's customized solutions are industry-specific and support build services from<br />collaboration spaces to digital twins.",

    //----------aboutHistory
    month: ['', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],

    about_hisoty_2024_03_1: 'Attend ‘The Geo Connect Asia 2024’ Conference in Singapore',
    about_hisoty_2024_02_1: 'Established YMX Singapore PTE LTD, a local corporation in Singapore',
    about_hisoty_2024_01_1: 'Attend ‘The CES 2024’ Conference in the US',
    about_hisoty_2024_01_2:
      'Signed a Joint Venture Agreement with YJP SURVEYORS PTE LTD in Singapore',

    about_hisoty_2023_12_1:
      'Awarded the Excellence Award at ‘the Metaverse Innovation Award’ by the Ministry of Science and ICT',
    about_hisoty_2023_12_2: 'Signed MOU with Yuhan University for ‘Industry-academic cooperation’',
    about_hisoty_2023_11_1: 'Partnership with Samsung SDS for ‘XR Digital Twin’',
    about_hisoty_2023_11_2:
      'MOU with Korea Polytechnic V University for ‘Training Future Talents in Digital Innovation Technology’',
    about_hisoty_2023_11_3:
      'CEO Park Tae-wook appointed as Director of the Military Operations Research Society of Korea',
    about_hisoty_2023_11_4:
      'Signed MOU with NHN Edu for ‘XR Technology and Metaverse Platform Business’',
    about_hisoty_2023_10_1:
      'Selected as the "Hidden Champion of Seoul" by the Seoul Metropolitan Government',
    about_hisoty_2023_08_1: 'Attracted a total of $4 million in ‘Series A’ investment',
    about_hisoty_2023_05_1:
      'Attended the Korea Society IT Service Association ‘2023 Spring Conference’',
    about_hisoty_2023_05_2:
      "Won the contract of the ‘Open AR Platform’ business of KISTI and led the industry's first ‘standardization’",
    about_hisoty_2023_05_3:
      'Launched a one-stop XR construction inspection solution and Metaverse platform (Mxspcae) linkage service',
    about_hisoty_2023_02_1:
      "Signed MOU with Sungkyunkwan University's Industry-University Institute Cooperation Center for ‘Industry-academic cooperation’",

    about_hisoty_2022_11_1:
      'Awarded the Minister of Public Administration and Security Award at ‘The Korea IT Service Innovation Award’',
    about_hisoty_2022_11_2:
      'Signed MOU with Smart Human Resources Development Center for ‘Business Cooperation on Digital Education Platform Based on Metaverse’',
    about_hisoty_2022_10_1: 'The company name changed from ‘Yellowtail’ to ‘YMX’',
    about_hisoty_2022_9_1: 'Attend The AOS Data Conference in Japan',
    about_hisoty_2022_7_1: 'Launched ‘MXspace’, The Industrial Metaverse Platform',
    about_hisoty_2022_7_2: 'Launched ‘MXrtc’ The Video, Voice Chat Solution',

    about_hisoty_2021_11_1:
      '‘MXview’ quality inspection solution awarded ‘21st Technology of the Year Award’ within Samsung Group',
    about_hisoty_2021_9_1: 'Launched ‘MXatg’, The XR-based equipment.inspection support solution',
    about_hisoty_2021_6_1: 'Launched ‘MXview’, The BIM-based AR quality inspection solution',
    about_hisoty_2021_1_1: 'Partnership with LGCNS for XR Digital Twin',

    about_hisoty_2020_12_1: 'Partnership with Rockwell Automation Korea',

    about_hisoty_2019_10_1: 'Partnership with Unity Korea',
    about_hisoty_2019_8_1: 'Launched ‘MXdts’ The Virtual Facility Training Simulator Solution',

    about_hisoty_2018_8_1: 'Launched ‘Mxiot’ The AI-based IOT solution',
    about_hisoty_2018_5_1: 'Partnership with PTC Korea',
    about_hisoty_2018_5_2: "Attend the Unity Korea's United Conference",
    about_hisoty_2018_2_1:
      'Named in High Seoul Brand Innovation Company by the Seoul Business Agency',
    about_hisoty_2018_1_1: 'Establishment of “Yellotail Co., Ltd.”',

    //business
    business_datail: 'More',

    business_MXspace_title: 'Enterprise Remote Collaboration<br />XR-based workspace platform',
    business_MXspace_body:
      "Based on YMX's self-development metaverse platform,<br />It provides all metaverse services that customers want by linking various solutions and contents, etc.",

    business_MXview_title:
      'Leading the digital construction management platform for XR Quality Inspenctrion Solution',
    business_MXview_body:
      'YMX provides an AR service by optimizing large-capacity BIM 3D data in EPC (Engineering, Procurement, Construction) fields such as construction, ships, and plants.<br />Maximize the efficiency of quality, inspection management, automation, and collaboration through real-time remote interworking between on-site and working systems.',

    business_MX3d_optimizer_title: 'CAD Converting, Spliting & Lightening',
    business_MX3d_optimizer_body:
      'It is the adaptable format to digital twin development engines of Unity 3D, Unreal,<br />and Nvidia Omnibus and supports conversion, lightweight, and segmentation functions.',

    business_MXdts_title: 'Digital Twin Simulator Services for Facility Operations',
    business_MXdts_body:
      'Based on the metaverse platform Mxspace, YMX are enable to support the digital twinning of smart factory factories into 3D digital virtual factories.',

    business_Mxtsg_title: 'Solutions for Industrial Site Special Precision Equipment Management',
    business_Mxtsg_body:
      'Based-on XR technology enables the support of various industries, including construction, aviation, national defense, and healthcare, to quickly acquire and utilize technology<br />through education, quality testing, condition checks, and maintenance assistance for specialty and precision instruments.',

    //project
    project_maintitle:
      'Based on digital innovation experiences in various industries,<br />we will create business success stories with our customers.',
    project_maintitle_m:
      'Based on digital innovation experiences<br />in various industries,<br />we will create business success stories with our customers',

    project_videoTitle_1: 'Facility Inspection space Recognition AR service',
    project_videoTitle_2: 'Industry on-site Monitoring AR Service',
    project_videoTitle_3: 'Marketing application AR Services',
    project_videoTitle_4: 'VR services for construction on-site inspection training',
    project_videoTitle_5: 'Heavy Equipment Performance Test AR Service',

    //contact
    contact_mainTitle_1: 'Contact us',
    contact_required_1: 'Fields are required',
    contact_bodyTitle_1: 'Type of inquiry',
    contact_bodyTitle_2: 'Customer Info',
    contact_bodyTitle_3: 'Business sector',
    contact_bodyTitle_4: 'Message',
    contact_bodyTitle_4_1: 'Please enter your message',

    contact_bodyText_1_1: 'Product Inquiry',
    contact_bodyText_1_2: 'Other',

    contact_bodyText_2_1: 'Name',
    contact_bodyText_2_1_2: 'Your name',
    contact_bodyText_2_2: 'Email',
    contact_bodyText_2_2_2: 'Email address',
    contact_bodyText_2_3: 'Phone',
    contact_bodyText_2_4: 'Company',
    contact_bodyText_2_4_2: 'Company/Institute',
    contact_bodyText_2_5: 'Dept.',
    contact_bodyText_2_6: 'Position',

    contact_bodyText_3_1: 'IT development',
    contact_bodyText_3_2: 'Service',
    contact_bodyText_3_3: 'Education',
    contact_bodyText_3_4: 'Medical',
    contact_bodyText_3_5: 'Public Institution',
    contact_bodyText_3_6: 'Wholesale and retail',
    contact_bodyText_3_7: 'Manufacturing',
    contact_bodyText_3_8: 'Other',

    contact_privacy_1: 'I agree to the privacy policy',
    // "contact_privacy_2": "에 동의합니다.",

    contact_complete_1: 'Your inquiry is complete.',
    contact_complete_2: 'Confirm',

    contact_address_1: '1902, 19F, Seoul forest kolon digital tower, 25, Seongsuil-ro 4-gil',
    contact_address_2: 'Seongdong-gu, Seoul, Korea 04781',
    contact_singapore_address: '41 Middle Road #04-00 Boon Sing Building, Singapore 188950',
    contact_menu_1: 'Purchase and Affiliate Inquiries',
    contact_menu_2: 'Product use and technical advice',
  },
  jp: {
    //nav_menu
    nav_menu_1: '会社紹介',
    nav_menu_2: 'ソリューション',
    nav_menu_3: '活用事例',
    nav_menu_4: '問い合わせ',

    // footer
    footer_partnership_1: '提携のお問い合わせ',
    footer_partnership_2: 'お客様のデジタル業務革新の悩みを迅速に解決します。',
    footer_partnership_3: '問い合わせ',
    footer_address_1:
      '(04781) ソウルトゥクピョルシ ソンドング ソンスイルロ4(サ)ギル 25, 19階 1902号 02-465-3737',
    footer_singapore_address: '41 Middle Road #04-00 Boon Sing Building, Singapore 188950',
    footer_privacy: '個人情報取扱方針',

    //MainPage
    //----------MainName
    mainpage_mainName_1: 'メタバスベースの新しいデジタルイノベーションを提供します。',
    mainpage_mainName_2: 'XRベースのメタバースプラットフォームで新しい経験をお届けします。',
    mainpage_mainName_3: '最高の専門家が集まり、メタバス産業をリードします。',

    //NewMainPage
    //----------MainName
    newMainPage_headTitle_1: 'XRベースの企業型リモート<br />遠隔協業プラットフォーム専門企業',
    newMainPage_pc_bodyText:
      '製造、EPC、教育、ヘルスケアなど産業全般のデジタル革新のための企業型リモート遠隔協業プラットフォームを提供<br />協業空間からデジタルツインまで産業別オーダーメード型サービス構築支援',
    newMainPage_mb_bodyText:
      '製造、EPC、教育、ヘルスケアなど産業全般の<br />デジタル革新のための企業型リモート遠隔協業プラットフォームを提供<br />協業空間からデジタルツインまで産業別オーダーメード型サービス構築支援',

    //----------MainTechnology
    newMainPage_technology1_Title: '産業用メタバース専門企業',
    newMainPage_pc_technology1_Text: '産業とメタバース技術を融合させ、産業現場の問題を解決します。',
    newMainPage_mb_technology1_Text:
      '産業とメタバース技術を融合させ、産業現場の<br />問題を解決します。',
    newMainPage_technology2_Title: '顧客価値の実現',
    newMainPage_pc_technology2_Text:
      '各分野の専門知識に基づいて、<br />お客様に合わせたソリューションを提供します。',
    newMainPage_mb_technology2_Text:
      '各分野の専門知識に基づいて、<br />お客様に合わせたソリューションを提供します。',

    //----------MainSolution
    newMainPage_solution_MXspace_txt:
      '企業型リモート協業XR基盤<br />ワークスペース·プラットフォーム',
    newMainPage_solution_pc_MXview_txt:
      '大規模プラント施工のための<br />先導的デジタル<br />建設管理プラットフォーム',
    newMainPage_solution_mb_MXview_txt:
      '大規模プラント施工のための<br />先導的デジタル建設管理プラットフォーム',
    newMainPage_solution_pc_MX3d_optimizer_txt:
      'CADデータコンバージョン<br />自動分割軽量化ソリューション',
    newMainPage_solution_mb_MX3d_optimizer_txt:
      'CADデータコンバージョン自動分割<br />軽量化ソリューション',
    newMainPage_solution_MXdts_txt:
      '設備運営のためのデジタルツイン<br />トレーニング·シミュレーター·サービス',
    newMainPage_solution_pc_MXatg_txt:
      '産業現場の 特殊精密機器<br />管理の ための 先導的<br />デジタル 建設管理ソリューション',
    newMainPage_solution_mb_MXatg_txt:
      '産業現場の 特殊精密機器 管理の<br />ための 先導的 デジタル<br />建設管理ソリューション',

    //----------MainNews
    mainNews_txt_1:
      'イエローテイル社CEO、パク・テウク ​リモートワークのための業界特化型メタバース」',
    mainNews_txt_2: '総務大臣賞受賞 行政安全部​ 長官賞を受賞 韓国ITサービス革新大賞',
    mainNews_txt_3:
      'YMX、産業用メタバース・プラットフォーム 2022韓国ソフトウェアコンペティション 2022コリア・ソフトウェア・コンペティション',
    mainNews_txt_4:
      'YMX-スマート人材開発センターと覚書を締結 メタバースに基づ​ くデジタル教育プラットフォーム​',
    mainNews_txt_5: 'YMX、メタバース型「危機管理型防災サービス」を発表 防災サービス」を公開​',
    mainNews_txt_6: 'YMX、KISTI「オープンXRプラット フォーム 「プロジェクト受注 「初の標準化」',
    mainNews_txt_7:
      'YMX、「デジタル・プラット フォーム・ガバメント」に参加 デジタル・プラットフォーム・​ガバメント 国際会議​',
    mainNews_txt_8: 'YMX、「メタバース」サービスを受注 KOREATECH大学より',
    mainNews_txt_9: 'YMXがARソリューションを発表 トラブルシューティング、検出、​解決 建設機械設備​',
    mainNews_txt_10: 'YMX、「シリーズA」で総額400万ドルの投資を獲得',
    mainNews_txt_11:
      '[CES 2024］YMXのパク・テウクCEO ​「国内外のバイヤーから 国内外バイヤーからの​ 大きな関心を期待」',

    mainNews_channel_et_news: '전자신문',
    mainNews_channel_wowtv: '한국경제TV',
    mainNews_channel_d_daily: '디지털데일리',
    mainNews_channel_fn_nfews: '파이낸셜뉴스',
    mainNews_channel_newsis: '뉴시스',
    mainNews_channel_chosun_biz: '조선비즈',
    mainNews_channel_mk: '매일경제',
    mainNews_channel_e_today: '이투데이',

    //about
    about_headTitle: 'XRベースのMetaverseプラットフォーム専門企業 YMX',
    about_headTitle_m: 'XRベースのMetaverse<br/>プラットフォーム専門企業 YMX',

    about_bodyText:
      '製造、EPC、教育、ヘルスケアなど産業全般のデジタル革新のためのメタバスプラットフォームを提供<br/>協業空間からデジタルツインまで産業別オーダーメード型サービス構築支援',
    about_bodyText_m:
      '製造、EPC、教育、ヘルスケアなど産業全般の<br/>デジタル革新のためのメタバスプラットフォームを提供<br/>協業空間からデジタルツインまで産業別オーダーメード型サービス構築支援',

    //----------aboutHistory
    month: ['', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],

    about_hisoty_2024_03_1: 'シンガポールGeoConnect Asia 2024カンファレンス参加',
    about_hisoty_2024_02_1: 'シンガポール現地法人YMX Singapore PTE LTD設立',
    about_hisoty_2024_01_1: '米国CES2024カンファレンス参加',
    about_hisoty_2024_01_2: 'シンガポールYJP SURVEYORS PTE LTDとJoint Venture(合弁投資)契約締結',

    about_hisoty_2023_12_1: '科学技術情報通信部メタバースイノベーションアワード優秀賞受賞',
    about_hisoty_2023_12_2: '柳韓大学産学協力MOU締結',
    about_hisoty_2023_11_1: 'サムスンSDS XR Digital Twinパートナーシップ締結',
    about_hisoty_2023_11_2: '韓国ポリテクV大学デジタル革新技術分野の未来人材養成MOU締結',
    about_hisoty_2023_11_3: 'Tae-Wook Park 代表 韓国国防経営分析学会理事委嘱',
    about_hisoty_2023_11_4: 'NHNエデュXR技術及びメトップバスプラットフォーム事業MOU締結',
    about_hisoty_2023_10_1: 'ソウル市がソウル型強小企業に選定',
    about_hisoty_2023_08_1: '総53億ウォン規模 ‘シリーズA’ 投資誘致',
    about_hisoty_2023_05_1: '韓国ITサービス学会 ‘2023春季学術大会’ カンファレンス参加',
    about_hisoty_2023_05_2: 'KISTI ‘開放型ARプラットフォーム’ 事業受注及び業界初の ‘標準化’',
    about_hisoty_2023_05_3:
      '‘ワンストップ’ XR建設施工検収ソリューションとメタバスプラットフォーム(Mxspcae)連携サービスを発売',
    about_hisoty_2023_02_1: '成均館大学校産学協力団産学協力MOU締結',

    about_hisoty_2022_11_1: '‘大韓民国ITサービス革新大賞’ 行政安全部長官賞受賞',
    about_hisoty_2022_11_2:
      'スマート人材開発院 ‘メタバス基盤デジタル教育プラットフォーム事業協力’ MOU締結',
    about_hisoty_2022_10_1: 'YellowtailからYMXに社名変更',
    about_hisoty_2022_9_1: '日本AOS Dataカンファレンス参加',
    about_hisoty_2022_7_1: '産業用メタバースプラットフォーム ‘MXspace’ 発売',
    about_hisoty_2022_7_2: '画像、音声チャットソリューション ‘MXrtc’ 発売',

    about_hisoty_2021_11_1:
      '‘MXview’ 品質検収ソリューションを基盤にサムスングループ内で ‘21年今年の技術賞’ を受賞',
    about_hisoty_2021_9_1: 'XRベースの設備·点検支援ソリューション ‘MXatg’ を発売',
    about_hisoty_2021_6_1: 'BIMベースのAR品質検収ソリューション ‘MXview’ を発売',
    about_hisoty_2021_1_1: 'LG CNS XR Digital Twin パートナーシップ締結',

    about_hisoty_2020_12_1: 'ロックウェル·オートメーション·コリア·パートナーシップ締結',

    about_hisoty_2019_10_1: 'Unityコリアパートナーシップ締結',
    about_hisoty_2019_8_1: '仮想設備教育訓練シミュレーターソリューション ‘MXdts’ を発売',

    about_hisoty_2018_8_1: 'AIベースのIoTソリューション ‘MXiot’ を発売',
    about_hisoty_2018_5_1: 'PTCコリアパートナーシップ締結',
    about_hisoty_2018_5_2: 'UnityコリアUniteカンファレンス参加',
    about_hisoty_2018_2_1: 'ソウル産業振興院ハイソウルブランド革新企業選定',
    about_hisoty_2018_1_1: 'Yellowtail設立',

    //business
    business_datail: '詳細を見る',

    business_MXspace_title:
      'XRベースのリモートコラボレーション<br />プラットフォーム企業型メタバース',
    business_MXspace_body:
      '独自開発したメタバスプラットフォームを基盤に<br />様々なソリューション、コンテンツなどを連動させ、お客様が望むすべてのメタバースサービスを提供します。',

    business_MXview_title: '産業建築施工のための <br />デジタル品質管理ソリューション',
    business_MXview_body:
      '建設、船舶、プラントなどEPC分野の大容量BIM 3Dデータの最適化を通じてARサービスとして提供します。<br />現場と業務システムのリアルタイム遠隔連動を通じて品質·検収管理·自動化·協業などの効率性を極大化します。',

    business_MX3d_optimizer_title: 'CADフォーマット変換、分割、および軽量化ソリューション',
    business_MX3d_optimizer_body:
      'デジタルツイン開発エンジンであるユニティ3D、アンリアル、NVIDIAオムニバスに適用可能なフォーマットで<br />コンバージョン及び軽量化、分割機能をサポートします。',

    business_MXdts_title: '設備運営のためのデジタルツイン<br />仮想シミュレーター',
    business_MXdts_body:
      'メタバスプラットフォームMXspaceをベースにスマートファクトリー工場を3Dデジタル仮想工場として<br/>デジタルツイン化できるよう支援します。',

    business_Mxtsg_title: '産業現場特殊精密機器管理のための<br />XRデジタル管理ソリューション',
    business_Mxtsg_body:
      'XR技術基盤で建設、航空、国防、医療など多様な産業現場で特殊·精密機器に対する教育、性能テスト、<br/>状態点検、整備補助などの活用を通じて迅速に技術を確保および活用できるよう支援します。',

    //project
    project_maintitle:
      '様々な産業群におけるデジタルイノベーションの経験をもとに、<br/>お客様と共にビジネスの成功事例を作っていきます。',
    project_maintitle_m:
      '様々な産業群での<br/>デジタル革新経験をもとに<br/>お客様と共にビジネス成功事例を作っていきます。',
    project_videoTitle_1: '施設点検空間認識ARサービス',
    project_videoTitle_2: '文具産業現場モニタリングARサービス',
    project_videoTitle_3: 'マーケティングアプリARサービス',
    project_videoTitle_4: '建設現場検測教育用VRサービス',
    project_videoTitle_5: '重機性能試験ARサービス',

    //contact
    contact_mainTitle_1: '問い合わせすること',
    contact_required_1: '項目は必須入力事項',
    contact_bodyTitle_1: '問い合わせタイプ',
    contact_bodyTitle_2: '顧客情報',
    contact_bodyTitle_3: '産業群別',
    contact_bodyTitle_4: 'お問い合わせの内容',
    contact_bodyTitle_4_1: '詳細を入力してください',

    contact_bodyText_1_1: '製品のお問い合わせ',
    contact_bodyText_1_2: 'その他',

    contact_bodyText_2_1: '顧客名',
    contact_bodyText_2_1_2: '顧客名',
    contact_bodyText_2_2: 'イーメール',
    contact_bodyText_2_2_2: 'イーメール',
    contact_bodyText_2_3: '連絡所',
    contact_bodyText_2_4: '企業/機関',
    contact_bodyText_2_4_2: '企業/機関',
    contact_bodyText_2_5: '部署 ',
    contact_bodyText_2_6: '職級 ',

    contact_bodyText_3_1: 'IT開発',
    contact_bodyText_3_2: 'サービス',
    contact_bodyText_3_3: '教育',
    contact_bodyText_3_4: '医療',
    contact_bodyText_3_5: '公共機関',
    contact_bodyText_3_6: '卸小売',
    contact_bodyText_3_7: '製造業',
    contact_bodyText_3_8: 'その他',

    contact_privacy_1: 'プライバシーポリシー',
    contact_privacy_2: 'に同意します。',

    contact_complete_1: 'お問い合わせが完了しました。',
    contact_complete_2: '完了',

    contact_address_1: 'ソウルトゥクピョルシ ソンドング ソンスイルロ4(サ)ギル 25',
    contact_address_2: '19階 1902号',
    contact_singapore_address: '41 Middle Road #04-00 Boon Sing Building, Singapore 188950',
    contact_menu_1: '購入及び提携に関するお問い合わせ',
    contact_menu_2: '製品使用および技術諮問',
  },
};
